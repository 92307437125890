.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0;

}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Navbar-Container {
  text-align: left;
}

.NavBar-Links {
  padding: 10px;
  text-decoration: none;
  float: right;
}

.NavBar-Link {
  padding: 10px;
}

.Chat-Body {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 60vh;
}

.Chat-Body ul {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  overflow-y: scroll;
}

.Chat-Body li {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.chat-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  border: #61dafb;
}

.Chat-Body li:nth-child(even) {
  background-color: #eee;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Chat-Input-Container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 70%;
  max-width: 500px;
}

#chat-input {
  flex: 1;
  min-height: 80px;
  max-height: 100px;
  resize: none;
  outline: none;
}


#send-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}